import { useEffect, useState } from "react";
import Select from "react-select";

export const CountrySelector = ({ selectedCountry, setSelectedCountry }: any) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, [setSelectedCountry]);

    useEffect(() => {
        if (typeof selectedCountry === 'string' && countries.length) {
            setSelectedCountry(countries.filter((e: any) => e.value === selectedCountry)[0]);
        }
    }, [countries, selectedCountry, setSelectedCountry]);

    return (
        <Select
            options={countries}
            value={selectedCountry}
            classNames={{
                container: () => "text-sm w-full font-medium",
                dropdownIndicator: () => "bg-[#fbfbfb] rounded-lg",
                valueContainer: () => "bg-[#fbfbfb] rounded-md",
            }}
            onChange={(selectedOption) => setSelectedCountry(selectedOption as any)}
        />
    );
}