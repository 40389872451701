import { isEmpty } from "lodash";

export const TEXT_ERRORS = {
  REQUIRED: "Merci de renseigner les champs obligatoire",
  EMAIL_NOT_VALID: "Email non valide",
  PASSWORD_NOT_EQUAL: "Passwords do not match",
  PASSWORD_NOT_STRONG:
    "Votre mot de passe est trop faible, il doit contenir au moins 6 caractères.",
};

export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const validatePassword = (password: string, option: any = {}) => {
  const {
    shouldHaveLower = false,
    shouldHaveUpper = false,
    shouldHaveNumber = false,
    minLength = 6,
  } = option;

  const lowerCaseLetters = /[a-z]/g;
  if (shouldHaveLower && !password.match(lowerCaseLetters)) {
    return false;
  }

  const upperCaseLetters = /[A-Z]/g;
  if (shouldHaveUpper && !password.match(upperCaseLetters)) {
    return false;
  }

  const numbers = /[0-9]/g;
  if (shouldHaveNumber && !password.match(numbers)) {
    return false;
  }

  if (password.length < minLength) {
    return false;
  }

  return true;
};

export const validateInput = (input: string, length: number) => {
  if (input.length > length) {
    return true;
  }
  return false;
};

export const assertNotEmpty = (obj: object) => {
  const empty = Object.values(obj).filter((e) => isEmpty(e));
  if (!isEmpty(empty)) throw Error(TEXT_ERRORS.REQUIRED);
};

export const checkIsEmpty = (obj: object) => {
  const empty = Object.values(obj).filter((e) => isEmpty(e));
  if (!isEmpty(empty)) return true;
  return false;
};

export const isObjEmpty = (obj: object) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const assertValidEmail = (email: string) => {
  if (!email) throw Error(TEXT_ERRORS.REQUIRED);
  if (!validateEmail(email)) throw Error(TEXT_ERRORS.EMAIL_NOT_VALID);
};

export const assertValidPassword = (
  password: string,
  confirmPassword: string
) => {
  if (!password || !confirmPassword) throw Error(TEXT_ERRORS.REQUIRED);
  if (password !== confirmPassword) throw Error(TEXT_ERRORS.PASSWORD_NOT_EQUAL);
  if (!validatePassword(password)) throw Error(TEXT_ERRORS.PASSWORD_NOT_STRONG);
};
