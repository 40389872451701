import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { auth } from '../../../services/firebase/auth/auth';
import { Spinner } from '../../../components/core/Loading';
import { getSubscriptions } from '../../../services/apis/subscriptions/subscriptions';
import { getCustomerPanel } from '../../../services/firebase/panel';

const CustomerPanel = () => {
    const [searchParams] = useSearchParams();
    const [user, loading] = useAuthState(auth);
    const priceId = searchParams.get('to');

    const fetchBillingDetails = async () => {
        const userAuth: any = auth.currentUser
        const userToken: string = await userAuth.getIdToken();

        try {
            return axios.get(`${process.env.REACT_APP_SHOP_API_URL}/billinginformations`, {
                headers: {
                    Authorization: 'Bearer firebase ' + userToken,
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!loading && user?.uid) {
            fetchBillingDetails().then(({ data }: any) => {
                getSubscriptions().then((subs) => {
                    const ids = ["prod_P5VgO1eFCrPJ4c", "prod_P3hE71Ys1fDC9f", "prod_P15L7uqJrQtz65", "prod_NoU5hahJkI3Xok", "prod_NZB45VbQj7mY6p", "prod_Ln526US9rYE2A6"];
                    const activeSubs = subs.filter((e: any) => e.status === 'active');
                    const activeMoneyGameSubscription = activeSubs.filter((subscription: any) => ids.includes(subscription.prices[0].price.product.stripe_id))?.[0];
                    getCustomerPanel({
                        userId: data.stripe_id,
                        subscriptionId: activeMoneyGameSubscription.subscription_id,
                        returnUrl: `${window.location.origin}/`,
                        afterCompletionUrl: `${window.location.origin}/`,
                        priceId
                    }).then((response: any) => {
                        window.open(response.data.panelUrl, '_self');
                    })
                })
            })
        }
    }, [loading, priceId, user?.uid])

    useEffect(() => {
        if (!user?.uid && !loading) {
            window.location.href = '/login';
        }
    }, [loading, user?.uid])

    return (
        <div className='w-screen h-screen overflow-auto'>
            <Spinner />
        </div>
    )
}

export default CustomerPanel