import { combineReducers } from 'redux';
import auth from './userAuth/user';
import subscriptions from './subscription/subscription';

const staticReducers = { auth, subscriptions };

function createReducer(asyncReducers: any) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

const rootReducer = createReducer(staticReducers);

export default rootReducer;
