import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Col, Row } from "../../core/Container";
import { Logo } from "../../generic/Logo";
import { auth, signOutUser } from "../../../services/firebase/auth/auth";
import { Spinner } from "../../core/Loading";
import { Button } from "../../core/Button";
import { getCheckoutDetails } from "../../../services/apis/checkout/plans";
import { PlanTypes } from "../../../types/plan.types";
import { setLang } from "../../../i18n/i18n";

import CheckoutSignup from "./Auth/CheckoutSignup";
import CheckoutSignin from "./Auth/CheckoutSignin";
import PayForm from "./PayForm";

const Checkout: FC = () => {
  const { lang } = useParams();
  const { slug } = useParams();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>();
  const [currentAuth, setCurrentAuth] = useState("signup");
  const [plan, setPlan] = useState<PlanTypes>();

  useEffect(() => {
    if (slug)
      getCheckoutDetails(slug).then(({ data }: any) => {
        setPlan(data);
      });
  }, [slug]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) setCurrentStep(2);
      else setCurrentStep(1);
    });
  }, []);

  const onSignOut = useCallback(() => {
    signOutUser();
  }, []);

  const leftColContent = useMemo(() => {
    const contentLang = lang ?? "fr";
    setLang(contentLang);
    const productDetails = plan?.product.translations.filter(
      (e) => e.locale === contentLang
    )?.[0];
    if (!plan?.product) {
      return <Spinner />;
    }
    return (
      <Col className="w-full h-full xl:px-24 md:px-12 px-2">
        <h1 className="font-bold">{plan?.product.name}</h1>
        <img
          alt={plan?.product.name}
          src={plan?.product?.image?.url ?? ""}
          width={1000}
          height={1000}
          className="xl:w-1/2 w-full h-fit self-center my-4"
        />
        <Row className="gap-4 items-start justify-center grid grid-cols-1 sm:grid-cols-3  border-b-2 pb-10 border-gray-300 overflow-hidden md:flex-row flex-col">
          {productDetails?.descriptions.map((desc) => {
            return (
              <Col className="flex-1 gap-3" key={desc.id}>
                <h3 className="font-bold">{desc.name}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: desc.description }}
                  className="flex flex-col"
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    );
  }, [plan, lang]);

  return (
    <div className="w-full h-screen">
      <div className="w-full h-[60px] bg-white items-center justify-between flex px-10 drop-shadow-md">
        <Logo />
        {currentStep === 2 && (
          <Button onClick={onSignOut}>{t("logout")}</Button>
        )}
      </div>
      <div className="w-full h-[calc(100vh-60px)] flex lg:flex-row flex-col-reverse justify-end">
        <div className="xl:flex-[2] lg:flex-1 bg-[#FCFCFC] md:py-10 py-8 items-center flex flex-col">
          {leftColContent}
        </div>
        <Col
          className={clsx(
            { "min-h-[700px] py-10": currentStep == 1 },
            "xl:flex-[1.2] lg:flex-[0.7] w-full bg-white shadow-lg xl:px-10 px-2"
          )}
        >
          {currentStep == null ? (
            <Spinner />
          ) : (
            <>
              {currentStep === 1 && (
                <Col className="relative w-[80%] self-center gap-2">
                  <Row className="w-full">
                    <p className="text-[#5D5C61] flex-1 text-center">
                      {t("step")} 1
                    </p>
                    <p
                      className={clsx(
                        {
                          "text-[#E2E2E2]": currentStep === 1,
                        },
                        "flex-1 text-center"
                      )}
                    >
                      {t("step")} 2
                    </p>
                  </Row>
                  <div className="w-full h-[8px] rounded-full overflow-hidden bg-[#E2E2E2]">
                    <div
                      className={clsx(
                        {
                          "w-1/2": currentStep === 1,
                        },
                        "green-gradient-button-bg h-full"
                      )}
                    />
                  </div>
                </Col>
              )}
              {currentStep === 1 ? (
                currentAuth === "signup" ? (
                  <CheckoutSignup
                    onSuccess={() => {
                      setCurrentStep(2);
                    }}
                    onLoginPressed={() => {
                      setCurrentAuth("login");
                    }}
                  />
                ) : (
                  <CheckoutSignin
                    onSuccess={() => {
                      setCurrentStep(2);
                    }}
                    onSignupPressed={() => {
                      setCurrentAuth("signup");
                    }}
                  />
                )
              ) : (
                <PayForm plan={plan} />
              )}
            </>
          )}
        </Col>
      </div>
    </div>
  );
};

export default Checkout;
