// eslint-disable-next-line @typescript-eslint/no-var-requires
let mixpanel = require('mixpanel-browser');

const parseUser = (user: any) => {
    return {
        name: user.displayName,
        email: user.email,
        number: user.phoneNumber,
        uid: user.uid,
        createdAt: user.metadata.createdAt,
        emailVerified: user.emailVerified
    }
}

export const mixpanelSignup = async (user: any) => {
    mixpanel.identify(user.uid);
    const token = await user.getIdToken();
    mixpanel.track('Checkout Signup event', {
        user: { ...parseUser(user), token: JSON.stringify(token) },
    })
    mixpanel.people.set({ "$email": user.email, name: user.displayName, created: user.createdAt, lastLogin: user.createdAt });
}

export const mixpanelSignin = async (user: any) => {
    mixpanel.identify(user.uid);
    const token = await user.getIdToken();
    mixpanel.track('Checkout Signin event', {
        user: { ...parseUser(user), token: JSON.stringify(token)},
    })
    mixpanel.people.set({ "$email": user.email, name: user.displayName, created: user.createdAt, phone: user.phoneNumber, lastLogin: user.lastLoginAt });
}

export const mixpanelCheckout = (id: string, status: 'success' | 'failed', token: string, stripeId: string, error?: string) => {
    mixpanel.identify(id);
    mixpanel.track('Checkout event', {
        details: {
            status,
            firebaseId: id,
            token: JSON.stringify(token),
            stripeId,
            error
        },
    })
}