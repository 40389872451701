import axios from "axios";

export const ZapierHooks = {
  signup: "https://hooks.zapier.com/hooks/catch/2602757/35ja6ct/",
};

export function sendNewSignUpsToZapierHook({
  email,
  firstName,
  lastName,
  phone,
  tag,
  slug,
}: {
  email: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  tag: string | null;
  slug: string | null;
}) {
  return axios
    .create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
    .post(ZapierHooks.signup, {
      email,
      firstName,
      lastName,
      phone,
      tag,
      slug,
      type: "signup",
    });
}
