import axios from "axios"
import { auth } from "../../firebase/auth/auth"



export const getInvoices = async () => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth.getIdToken();
    try {
        const invoices = await axios({
            url: `${process.env.REACT_APP_SHOP_API_URL}/invoices`, method: 'GET', headers: {
                Authorization: `Bearer firebase ${userToken}`,
            }
        })
        return invoices?.data
    } catch (error) {
        console.log(error)
    }
}

export const getSubscriptions = async () => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth.getIdToken();
    try {
        const subscriptions = await axios({
            url: `${process.env.REACT_APP_SHOP_API_URL}/subscriptions`, method: 'GET', headers: {
                Authorization: `Bearer firebase ${userToken}`,
            }
        })
        return subscriptions?.data
    } catch (error) {
        console.log(error)
    }
}


export const downloadInvoicePDF = async (invoiceId: string) => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth.getIdToken();
    try {
        const invoice: any = await axios({
            url: `${process.env.REACT_APP_SHOP_API_URL}/invoices/${invoiceId}`, method: 'GET', headers: {
                Authorization: `Bearer firebase ${userToken}`,
            }
        })
        // const { url } = await response.json()
        //  console.log(url)
        window.open(invoice?.data.url, '_blank')
    } catch (error) {
        console.error(error)
    }
}