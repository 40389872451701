
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "./auth/auth";


const functions = getFunctions(app, "europe-west3");
const createCustomerPanelHttpsCallable = httpsCallable(functions, 'createNewStripePanel', {});

export const getCustomerPanel = async (data: any) => {

    return await createCustomerPanelHttpsCallable(data);

}