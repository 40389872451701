import moment from "moment";
import numeral from "numeral";

export class EnumUtil {
  public static isSomeEnum<T>(e: T) {
    const obj = Object.values(e);
    return (token: unknown): token is T[keyof T] =>
      obj.includes(token as T[keyof T]);
  }

  public static key<T>(E: T) {
    const obj = new Map<T[keyof T], keyof T>();

    Object.entries(E).forEach(([k, v]) => {
      obj.set(v as T[keyof T], k as keyof T);
    });

    return (e: T[keyof T]): keyof T => {
      const res = obj.get(e);
      if (!res) throw Error("error type enum");
      return res;
    };
  }
}

export const isError = (error: unknown): error is Error => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const allAvailableNumber = (except: any, max: number = 100) => {
  const availableIds = [];

  for (let i = 0; i < max; i++) {
    if (!except.includes(i)) {
      availableIds.push(i);
    }
  }

  return availableIds;
}

export const getDateFromDays = async (days: string) => {
  return moment().add(days, 'days').toDate();
}

export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k]);
  return obj;
};

export const firstCap = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const formatInt = (num: number) => {
  if (num < 10000) {
    return numeral(num).format("0,0",);
  } else {
    return numeral(num).format("0.0a", value => Math.floor(value));
  }
}

export const getCookie = (cname: any) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};