import { FC, useEffect, useMemo, useState } from "react";
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { useParams } from "react-router";

import { Spinner } from "../../../core/Loading";
import { PlanTypes, PriceTypes } from "../../../../types/plan.types";
import { couponType } from "../../../../utils/constants/plans";

import Form from "./Form";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "");

const PayForm: FC<{ plan?: PlanTypes }> = ({ plan }) => {
    const [price, setPrice] = useState<PriceTypes | undefined>(plan?.prices?.[0]?.price);
    const [coupon, setCoupon] = useState<couponType>();
    const { lang } = useParams();
    const formLang = useMemo(() => lang ?? "fr", [lang]);

    const totalPrice = useMemo(() => coupon?.valid ? coupon.coupon.percent_off ? Math.round((price?.amount ?? 1) * coupon.coupon.percent_off) : ((price?.amount ?? 0) - (coupon.coupon.amount_off ?? 0)) : price?.amount, [coupon, price?.amount]);

    const options: StripeElementsOptions = useMemo(() => ({
        mode: 'payment',
        amount: totalPrice,
        currency: price?.currency,
        locale: formLang as any,
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400,500,600'
            }
        ],
        appearance: {
            theme: 'flat',
            variables: {
                colorText: 'black',
                borderRadius: '12px',
                fontFamily: 'Poppins,sans-serif',
                colorTextPlaceholder: 'black'
            }
        },
        paymentMethodCreation: 'manual',
    }), [formLang, price?.currency, totalPrice]);

    useEffect(() => {
        if (!price) {
            setPrice(plan?.prices?.[0]?.price)
        }
    }, [plan, price])

    if (plan == null || price?.amount == null || price?.currency == null) {
        return <Spinner />;
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            <Form plan={plan} setPrice={setPrice} price={price} coupon={coupon} setCoupon={setCoupon} />
        </Elements>
    );
}

export default PayForm;