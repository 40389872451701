import clsx from "clsx";

export const LoadingSpinner = () => (
  <div className="popup-container flex fdc aic jcc">
    <div className="loading-button-overlay flex fdc aic jcc">
      <div className="loading-main-button flex fdc aic jcc" />
    </div>
  </div>
);

export const Spinner = () => (
  <div className={clsx("flex flex-col items-center justify-center", "loading-button-overlay")}>
    <div className={clsx("flex flex-col items-center justify-center", "loading-main-button")} />
  </div>
);
