import clsx from "clsx";
import { HTMLAttributes, LegacyRef, DetailedHTMLProps, FC } from "react";

export interface FlexComponentType extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  reference?: LegacyRef<HTMLDivElement> | undefined;
}


export const Container = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => <div className={clsx('flex aic jcc', className)}>{children}</div>;

export const TailwindContainer = ({
  children,
  className
}: {
  children: any;
  className?: string;
}) => <div className={clsx("min-h-screen flex flex-col justify-between items-center", className)}>
    <div className="flex flex-col flex-1 h-full md:gap-20 gap-10 container">
      {children}
    </div>
  </div>;

export const ContainerWithRef = ({
  children,
  className,
  reference
}: {
  children: any;
  className?: string;
  reference: any,

}) => <div ref={reference} className={clsx('flex aic jcc', className)}>{children}</div>;

export const Wrapper = ({
  children,
  className,
  onClick,
}: {
  children: any;
  className?: string;
  onClick?: any;
}) => (
  <div className={clsx(className)} onClick={onClick}>
    {children}
  </div>
);

export const Row: FC<FlexComponentType> = ({
  children,
  className,
  reference,
  ...props
}) => <div className={clsx("flex fdr", className)} ref={reference} {...props}>{children}</div>;

export const Col: FC<FlexComponentType> = ({
  className,
  reference,
  ...props
}) => <div className={clsx('flex fdc', className)} onClick={props.onClick}>{props.children}</div>;


export const Grid = ({
  children,
  className,
  onClick
}: {
  children: any;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;

}) => <div className={clsx('grid aic', className)} onClick={onClick}>{children}</div>;

