import { FC, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { LoadingSpinner } from "./components/core/Loading";
import Routing from "./routes/Routes";
import store from "./services/redux/store";
import { ToastContainer } from "react-toastify";
import Tap from "@tapfiliate/tapfiliate-js";
import "react-toastify/dist/ReactToastify.css";

import "./i18n/i18n";
import { initLocale } from "./i18n/i18n";

const App: FC = () => {
  initLocale()
  useEffect(() => {
    Tap.init('47596-2c130e');
  }, []);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Provider store={store}>
        <ToastContainer />
        <Routing />
      </Provider>
    </Suspense>
  );
};

export default App;
