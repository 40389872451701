import { FC, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import { MODE_DEBUG } from "../utils/constants/config";
import CheckoutPage from "../pages/app/Checkout/Checkout";
import CustomerPanel from "../pages/app/Checkout/CustomerPanel";

const NotFound = lazy(() => import("../pages/NotFound"));
// eslint-disable-next-line @typescript-eslint/no-var-requires
let mixpanel = require("mixpanel-browser");


const Routing: FC = () => {
  useEffect(() => {
    mixpanel.init("ce883702abb1e48bc2fbbeaa06922dde", {
      api_host: "https://proxy-cgouuewyoa-uc.a.run.app",
      debug: MODE_DEBUG,
      disable_persistence: true,
      ignore_dnt: true,
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path=":lang/:slug" element={<CheckoutPage />} />
        <Route path="/:slug" element={<CheckoutPage />} />
        <Route path="user-management" element={<CustomerPanel />} />
      </Routes>
    </BrowserRouter>
  )
};

export default Routing;