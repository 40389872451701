import { FC } from "react";
import "../../../components/App/Checkout/Auth/Auth.scss";
import Checkout from "../../../components/App/Checkout";

//get URL path and save it in local storage
localStorage.setItem("slug", window.location.pathname.replace(/^\//, ""));

//get tag query parameter and save it in loca storage
const urlParams = new URLSearchParams(window.location.search);
const tag = urlParams.get("tag");

localStorage.setItem("tag", tag!);

const CheckoutPage: FC = () => {
  return (
    <div className="w-screen h-screen overflow-auto">
      <Checkout />
    </div>
  );
};

export default CheckoutPage;
