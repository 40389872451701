export const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="87.811" height="28.77" viewBox="0 0 87.811 28.77">
            <defs>
                <linearGradient id="linear-gradient" x1="0.057" y1="0.069" x2="0.921" y2="0.931" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#71bd22"></stop>
                    <stop offset="1" stopColor="#00ab89"></stop>
                </linearGradient>
            </defs>
            <g id="Group_13127" data-name="Group 13127" transform="translate(-1073.604 135.91)">
                <g id="Group_14" data-name="Group 14" transform="translate(1073.604 -135.909)">
                    <g id="Group_13" data-name="Group 13">
                        <g id="Group_12" data-name="Group 12">
                            <path id="Rectangle_1" data-name="Rectangle 1" d="M5.964,0H22.8a5.964,5.964,0,0,1,5.964,5.964V22.8A5.964,5.964,0,0,1,22.8,28.769H5.964A5.964,5.964,0,0,1,0,22.8V5.964A5.964,5.964,0,0,1,5.964,0Z" fill="url(#linear-gradient)"></path>
                            <g id="Group_4" data-name="Group 4" transform="translate(7.913 7.044)">
                                <g id="Group_1-2" data-name="Group 1-2" transform="translate(0.459 7.257)" opacity="0.68">
                                    <path id="Path_1" data-name="Path 1" d="M1091.29-117.826l-7.084,7.058,9.455-4.8Z" transform="translate(-1084.173 117.861)" fill="#fff"></path>
                                    <path id="Path_2" data-name="Path 2" d="M1084.2-110.742a.034.034,0,0,1-.035-.034.035.035,0,0,1,.01-.025l7.084-7.058a.034.034,0,0,1,.048,0l0,0,2.37,2.262a.034.034,0,0,1,0,.049l-.009.006-9.455,4.8A.031.031,0,0,1,1084.2-110.742Zm7.085-7.043-6.907,6.879,9.215-4.676Z" transform="translate(-1084.164 117.871)" fill="#fff"></path>
                                </g>
                                <g id="Group_2" data-name="Group 2" transform="translate(0 0.058)" opacity="0.42">
                                    <path id="Path_3" data-name="Path 3" d="M1083.629-126.908v14.285l7.322-7.3Z" transform="translate(-1083.594 126.942)" fill="#fff"></path>
                                    <path id="Path_4" data-name="Path 4" d="M1083.619-112.6l-.014,0a.034.034,0,0,1-.021-.032v-14.285a.035.035,0,0,1,.021-.032.034.034,0,0,1,.038.007l7.323,6.989a.035.035,0,0,1,.011.025.037.037,0,0,1-.01.025l-7.323,7.3A.033.033,0,0,1,1083.619-112.6Zm.035-14.239v14.121l7.239-7.212Z" transform="translate(-1083.585 126.951)" fill="#fff"></path>
                                </g>
                                <g id="Group_3" data-name="Group 3" transform="translate(0.38)">
                                    <path id="Path_5" data-name="Path 5" d="M1084.108-126.98l9.821,9.375,4.328-2.2Z" transform="translate(-1084.073 127.015)" fill="#fff"></path>
                                    <path id="Path_6" data-name="Path 6" d="M1093.918-117.582a.034.034,0,0,1-.024-.01l-9.821-9.374a.035.035,0,0,1,0-.049.033.033,0,0,1,.038-.006l14.147,7.177a.034.034,0,0,1,.019.031.033.033,0,0,1-.019.031l-4.328,2.2A.044.044,0,0,1,1093.918-117.582Zm-9.625-9.27,9.632,9.194,4.245-2.153Z" transform="translate(-1084.064 127.024)" fill="#fff"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_5" data-name="Group 5" transform="translate(37.947 8.595)">
                        <path id="Path_7" data-name="Path 7" d="M1130.555-117.033h-6.12l-.877,1.68c-.321.584-.628.8-1.11.8a.964.964,0,0,1-.979-.947v0a1.542,1.542,0,0,1,.234-.73l4.017-7.6c.6-1.1,1.169-1.242,1.782-1.242.6,0,1.156.147,1.767,1.242l4.046,7.6a1.543,1.543,0,0,1,.218.73.949.949,0,0,1-.951.949h-.012c-.482,0-.8-.2-1.124-.8Zm-5.113-1.9h4.116l-2.06-3.929Z" transform="translate(-1121.469 125.068)" fill="#232326"></path>
                        <path id="Path_8" data-name="Path 8" d="M1141.495-115.533a1.022,1.022,0,0,1-1.022,1.022,1.022,1.022,0,0,1-1.023-1.022v-7.377c0-1.8.54-1.972,2.045-1.972h4.382a3.16,3.16,0,0,1,3.433,3.287,3.055,3.055,0,0,1-2.206,3.009l1.8,2.176a1.536,1.536,0,0,1,.482.993.947.947,0,0,1-.979.905c-.381,0-.687-.132-1.125-.687l-2.439-3.112h-3.345Zm4.236-7.45h-3.943c-.292,0-.292.147-.292.438v2.337h4.236c1.125,0,1.534-.555,1.534-1.387s-.408-1.388-1.534-1.388Z" transform="translate(-1125.195 125.03)" fill="#232326"></path>
                        <path id="Path_9" data-name="Path 9" d="M1161.142-115.573a1.024,1.024,0,0,1-1.016,1.03,1.024,1.024,0,0,1-1.03-1.016v-3.519l-4.557-4.207a1.076,1.076,0,0,1-.351-.759.976.976,0,0,1,.926-1.024c.036,0,.074,0,.11,0a1.093,1.093,0,0,1,.76.336l4.134,3.841,4.135-3.841a1.09,1.09,0,0,1,.759-.336.976.976,0,0,1,1.035.912.83.83,0,0,1,0,.11,1.074,1.074,0,0,1-.35.759l-4.557,4.207Z" transform="translate(-1128.249 125.069)" fill="#232326"></path>
                        <path id="Path_10" data-name="Path 10" d="M1178.234-117.033h-6.12l-.878,1.68c-.321.584-.628.8-1.109.8a.962.962,0,0,1-.979-.947v0a1.542,1.542,0,0,1,.233-.73l4.018-7.6c.6-1.1,1.168-1.242,1.781-1.242.6,0,1.153.147,1.767,1.242l4.046,7.6a1.525,1.525,0,0,1,.22.73.95.95,0,0,1-.951.949h-.012c-.483,0-.8-.2-1.126-.8Zm-5.112-1.9h4.119l-2.06-3.929Z" transform="translate(-1131.349 125.068)" fill="#232326"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}