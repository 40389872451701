import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import fr from "./locales/fr.json"
import 'moment/locale/fr';
import 'moment/locale/en-gb';
const resources = {
  fr,
  en,
}
export const supportedLanguages = ['fr', 'en'];

export const availableLanguages = Object.keys(resources)

export function setLang(lang: any) {
  i18n.changeLanguage(lang)
}
export async function initLocale() {
  i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      defaultNS: "common",
      lng: "fr",
      fallbackLng: "fr",
    });

}